import {
    ProductState,
    ProductStore,
} from "@apps/media_manager/stores/ProductStore";
import { QueryEntity } from "@datorama/akita";
import { map } from "rxjs/operators";
import { injectable } from "tsyringe";

@injectable()
export class ProductQuery extends QueryEntity<ProductState> {
    public constructor(private readonly productStore: ProductStore) {
        super(productStore);
    }

    all$ = this.selectAll();

    public selectProductAndVariant(
        productId: number,
        variantId: number | null
    ) {
        return this.selectAll().pipe(
            map((products) =>
                products.find((product) => product.id === productId)
            ),
            map((product) => ({
                product: product,
                variant: product?.variants.find((variant) => {
                    if (!variantId) {
                        return true;
                    }
                    return variant.id === variantId;
                }),
            }))
        );
    }
}
