import { array, number, object, string } from "superstruct";

const ReviewImagesSchema = object({
    url: string(),
    sizes: array(
        object({
            method: string(),
            w: number(),
            h: number(),
            url: string(),
        })
    ),
});

export default ReviewImagesSchema;
