import { globalLoggerToken, localizationInfoProviderToken } from "@/tokens";
import { GraphQLApiClient } from "@lib/GraphQLApiClient/GraphQLApiClient";
import { DependencyContainer, instanceCachingFactory } from "tsyringe";

import { ShopifyGraphQLProductApiService } from "./api_services/ShopifyGraphQLProductApiService";
import { ProductQuery } from "./queries/ProductQuery";
import { ProductService } from "./services/ProductService";
import { ProductStore } from "./stores/ProductStore";
import {
    productsApiServiceToken,
    productsQueryToken,
    productsServiceToken,
    productsStoreToken,
} from "./tokens";

export class ProductModule {
    registerProviders(di: DependencyContainer) {
        di.register(productsApiServiceToken, {
            useFactory: instanceCachingFactory(() => {
                const graphtQLApiClient = di.resolve(GraphQLApiClient);
                const localizationInfoProvider = di.resolve(
                    localizationInfoProviderToken
                );

                if (graphtQLApiClient) {
                    return new ShopifyGraphQLProductApiService(
                        graphtQLApiClient,
                        localizationInfoProvider.getCountryIsoCode(),
                        localizationInfoProvider.getLanguageIsoCode()
                    );
                }
            }),
        });
        di.register(productsStoreToken, {
            useFactory: instanceCachingFactory(() => {
                return new ProductStore();
            }),
        });
        di.register(productsQueryToken, {
            useFactory: instanceCachingFactory(() => {
                return new ProductQuery(di.resolve(productsStoreToken));
            }),
        });
        di.register(productsServiceToken, {
            useFactory: instanceCachingFactory(() => {
                return new ProductService(
                    di.resolve(productsApiServiceToken),
                    di.resolve(productsStoreToken),
                    di.resolve(globalLoggerToken)
                );
            }),
        });
        di.resolve(productsServiceToken).subscribe();
    }
}
