import ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import { InjectionToken } from "tsyringe";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import { ProductApiServiceInterface } from "./api_services/ProductApiServiceInterface";
import { ProductQuery } from "./queries/ProductQuery";
import { ProductService } from "./services/ProductService";
import { ProductStore } from "./stores/ProductStore";

export const mediaManagerApplicationLoggerToken = Symbol(
    "MediaManagerApplicationLoggerToken"
) as InjectionToken<LoggerInterface>;

export const mediaManagerAuthApiClientToken = Symbol(
    "mediaManagerAuthApiClientToken"
) as InjectionToken<ApiClientInterface>;

export const productsApiServiceToken = Symbol(
    "productsApiService"
) as InjectionToken<ProductApiServiceInterface>;

export const productsStoreToken = Symbol(
    "productsStore"
) as InjectionToken<ProductStore>;

export const productsServiceToken = Symbol(
    "productsService"
) as InjectionToken<ProductService>;

export const productsQueryToken = Symbol(
    "productsQuery"
) as InjectionToken<ProductQuery>;
