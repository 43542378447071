import { STATUS } from "@/constants/statuses";
import OffsetPaginationEntity from "@/entities/OffsetPaginationEntity";
import {
    ActiveState,
    EntityState,
    EntityStore,
    StoreConfig,
} from "@datorama/akita";
import { injectable } from "tsyringe";

import ReviewEntity from "../entities/ReviewEntity";

export interface ReviewsStateBase
    extends EntityState<ReviewEntity, number>,
        ActiveState {
    paginationMeta: OffsetPaginationEntity;
    status: STATUS;
}

function createInitialState(): ReviewsStateBase {
    return {
        active: null,
        paginationMeta: {
            perPage: 0,
            totalCount: 0,
            currentOffset: 0,
        },
        status: STATUS.VIRGIN,
    };
}

@injectable()
@StoreConfig({ name: "reviewsStoreBase" })
class ReviewsStoreBase extends EntityStore<ReviewsStateBase> {
    constructor() {
        super(createInitialState());
    }

    setStatus(status: STATUS) {
        this.update(() => ({
            status: status,
        }));
    }

    getStatus(): STATUS {
        return this.getValue().status;
    }
}

export default ReviewsStoreBase;
