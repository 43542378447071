import { STATUS } from "@/constants/statuses";
import { REVIEWS_WIDGET_NAMES } from "@/constants/widget-names";
import ReviewsListResponseEntity from "@apps/reviews/entities/ReviewsLIstResponseEntity";
import ReviewsListResponseSchema from "@apps/reviews/schemas/ReviewsListResponseSchema";
import ReviewsStoreBase from "@apps/reviews/stores/ReviewsStoreBase";
import { SortType } from "@apps/reviews/types";
import { applyTransaction } from "@datorama/akita";
import { ApiClientResponse, JsonMimeType } from "@lib/ApiClient";
import ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import { Observable } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { create } from "superstruct";
import { injectable } from "tsyringe";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import LikeResponseEntity from "../entities/LikeResponseEntity";
import ReviewEntity from "../entities/ReviewEntity";
import LikeResponseSchema from "../schemas/LikeResponseSchema";

type GetReviewsRequestParamsDTO = {
    productId?: number;
    offset?: number;
    orderBy?: SortType[] | string;
    search?: string;
    onlySite?: boolean;
    productsOnly?: boolean;
};

export type LeaveReviewCustomFormAnswer = {
    formId: number;
    questionsAnswers: {
        questionId: number;
        answer: number | number[] | string;
        questionType: string;
        answerType: string;
    }[];
};

export type LeaveReviewRequestParamsDTO = {
    productId?: number;
    title?: string;
    body: string;
    rating: number;
    images?: any[];
    guestEmail?: string;
    guestName?: string;
    customFormAnswers?: (LeaveReviewCustomFormAnswer | undefined)[];
    widget?: REVIEWS_WIDGET_NAMES;
    reviewRequestToken?: string;
};

export type CanLeaveReviewRequestParamsDTO = {
    productId?: number;
};

@injectable()
class ReviewsServiceBase {
    constructor(
        private reviewsStore: ReviewsStoreBase,
        private readonly authApiClient: ApiClientInterface,
        private readonly logger: LoggerInterface
    ) {}
    private getReviewsRequest(
        reqParams?: GetReviewsRequestParamsDTO
    ): Observable<ApiClientResponse<unknown>> {
        this.logger.debug(
            "ReviewsService.getReviewsRequest is started",
            reqParams
        );

        return this.authApiClient.get({
            url: "/review/getReviewList",
            responseType: "json",
            queryParams: reqParams,
        });
    }

    getReviews(
        reqParams?: GetReviewsRequestParamsDTO
    ): Observable<ReviewsListResponseEntity> {
        this.reviewsStore.setStatus(STATUS.INITIAL_LOADING);

        return this.getReviewsRequest(reqParams).pipe(
            map((v) => create(v.body, ReviewsListResponseSchema)),
            catchError((error: unknown) => {
                this.logger.debug(
                    "ReviewsService.getReviewsRequest is errored",
                    { reqParams, error }
                );
                applyTransaction(() => {
                    this.reviewsStore.setError(error);
                    this.reviewsStore.setStatus(STATUS.ERROR);
                });
                throw error;
            }),
            tap((v) => {
                this.logger.debug(
                    "ReviewsService.getReviewsRequest is ready",
                    reqParams
                );
                applyTransaction(() => {
                    this.reviewsStore.set(v.items);
                    this.reviewsStore.update(() => ({
                        paginationMeta: {
                            perPage: v.perPage,
                            totalCount: v.totalCount,
                            currentOffset: v.currentOffset,
                        },
                    }));
                    this.reviewsStore.setStatus(STATUS.READY);
                });
            })
        );
    }

    loadMoreReviews(
        reqParams: GetReviewsRequestParamsDTO
    ): Observable<ReviewsListResponseEntity> {
        this.reviewsStore.setStatus(STATUS.LOADING);

        return this.getReviewsRequest(reqParams).pipe(
            map((v) => create(v.body, ReviewsListResponseSchema)),
            catchError((error: unknown) => {
                this.logger.debug(
                    "ReviewsService.getReviewsRequest is errored",
                    reqParams
                );
                applyTransaction(() => {
                    this.reviewsStore.setError(error);
                    this.reviewsStore.setStatus(STATUS.ERROR);
                });
                throw error;
            }),
            tap((v) => {
                this.logger.debug(
                    "ReviewsService.getReviewsRequest is ready",
                    reqParams
                );
                applyTransaction(() => {
                    this.reviewsStore.add(v.items);
                    this.reviewsStore.update(() => ({
                        paginationMeta: {
                            perPage: v.perPage,
                            totalCount: v.totalCount,
                            currentOffset: v.currentOffset,
                        },
                    }));
                    this.reviewsStore.setStatus(STATUS.READY);
                });
            })
        );
    }

    toggleLike(reviewId: number): Observable<LikeResponseEntity> {
        return this.authApiClient
            .post({
                url: "/review/toggleLike",
                body: JSON.stringify({ reviewId }),
                responseType: "json",
                contentType: JsonMimeType,
            })
            .pipe(
                map((res) => create(res.body, LikeResponseSchema)),
                catchError((error: unknown) => {
                    this.logger.debug("ReviewsService.toggleReview is errored");
                    throw error;
                }),
                tap((reviewLikes) => {
                    this.logger.debug("ReviewsService.toggleReview is ready");
                    this.reviewsStore.update(reviewId, {
                        likes: reviewLikes.likes,
                        isCustomerLiked: reviewLikes.isCustomerLiked,
                    });
                })
            );
    }

    appendReview(review: ReviewEntity) {
        applyTransaction(() => {
            this.reviewsStore.add(review, { prepend: true });
            this.reviewsStore.update((state) => ({
                ...state,
                paginationMeta: {
                    ...state.paginationMeta,
                    totalCount: state.paginationMeta.totalCount + 1,
                },
            }));
        });
    }
}

export default ReviewsServiceBase;
